import { useTranslation } from 'react-i18next';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LayoutList } from 'layouts/list/LayoutList';
import { LogItem } from 'entities/logs';
import { ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import { setApplyFilters, setPage, setRowsPerPage, setSort } from '../apiSlice';
import { useColumns } from './useColumns';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { Filters } from './filters/Filters';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import { ApiFilter } from '../utils';
import { useSelector } from 'react-redux';

export const ApiLogsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const items: LogItem[] = useAppSelector(getItems);
  const pagination: PaginationProps = useAppSelector(getPagination);
  const totalNumber: number = useAppSelector(getTotalNumber);
  const itemsLoadingComputed: boolean = useAppSelector(getItemsLoadingComputed);
  const permissions: any = useAppSelector(getProfilePermission);
  const isOnRowClick = permissions.includes(UserPermissions.MERCHANT_GET);
  const filters: ApiFilter = useSelector(getFilters);

  const columns: ColumnProps[] = useColumns();

  const handleRowClick = (item: LogItem) => {
    history.push(`logs-api/details/${Base64.encodeURI(item.id)}`);
  };

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isFilterSelected = [
    filters.merchantsIds.length ||
      filters.apiGroup.length ||
      filters.eventId?.length ||
      filters.transactionType?.length ||
      filters.apiGroup.length ||
      filters.eventId?.length ||
      filters.terminalId?.length ||
      filters.transactionId?.length ||
      filters.cardHolderIp?.length ||
      filters.amount ||
      (filters.transactionDate?.from && filters.transactionDate?.to) ||
      filters.reference?.length,
  ].some((value) => !!value);

  return (
    <LayoutList title={t('logs.api.breadcrumb')}>
      <>
        <TableCard
          loading={itemsLoadingComputed}
          columns={columns}
          items={items}
          filters={<Filters />}
          filterApply={
            isFilterSelected ? () => dispatch(setApplyFilters({})) : undefined
          }
          totalNumber={totalNumber}
          setSort={setCurrentSort}
          onRowClick={isOnRowClick ? handleRowClick : undefined}
          onHover={isOnRowClick}
          pagination={pagination}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </>
    </LayoutList>
  );
};
