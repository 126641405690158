import { ItemAttributes } from '../../components/itemAttributeType';

export interface Log {
  clientName: string | null;
  createdTime: string | null;
  error: string | null;
  id: string;
  merchantName: string | null;
  apiGroup: string | null;
  eventType: string | null;
  eventId: string | null;
  programName: string | null;
  reference: string | null;
  request: any | null;
  response: any | null;
  responseTime: string | null;
  amount: number | null;
  transactionId: string | null;
  terminalId: string | null;
  cardHolderIp: string | null;
  transactionType: string | null;
  type: string | null;
}

export enum ApiGroup {
  ACQUIRING = 'ACQUIRING',
  ONBOARDING = 'ONBOARDING',
  PAYMENT_INSTRUMENTS = 'PAYMENT_INSTRUMENTS',
  TRANSACTIONS = 'TRANSACTIONS',
  API_KEY = 'API_KEY',
  WEBHOOKS = 'WEBHOOKS',
}

const transform = (value: any) => {
  const test = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
  return test;
};

export const apiGroupItems: ItemAttributes[] = [
  { label: transform(ApiGroup.ACQUIRING), value: ApiGroup.ACQUIRING },
  { label: transform(ApiGroup.ONBOARDING), value: ApiGroup.ONBOARDING },
  {
    label: transform(ApiGroup.PAYMENT_INSTRUMENTS.replace('_', ' ')),
    value: ApiGroup.PAYMENT_INSTRUMENTS,
  },
  { label: transform(ApiGroup.TRANSACTIONS), value: ApiGroup.TRANSACTIONS },
  {
    label: transform(ApiGroup.API_KEY?.replace('_', ' ')),
    value: ApiGroup.API_KEY,
  },
  { label: transform(ApiGroup.WEBHOOKS), value: ApiGroup.WEBHOOKS },
];

export interface LogItem {
  createdTime: string;
  id: string;
  amount: string;
  terminalId: string;
  cardHolderIp: string;
  transactionDate: string;
  transactionType: string;
  transactionId: string;
  reference: string;
  merchantName: string;
  eventType: string;
  eventId: string;
  apiGroup: ApiGroup;
  type: 'REQUEST' | 'RESPONSE' | 'REQUEST_RESPONSE';
}
